import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom'
import numeral from 'numeral';

import Content from '@/components/specific/Content'
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle'
import { getDetailTagihan } from '../../../utils/apis/InfoPelanggan';
import global from '../../../utils/helpers/global';

function Detail() {
    const navigate = useNavigate();
    const { search } = useLocation();
    const qparams = new URLSearchParams(search);

    const history = qparams.get('history') ?? null;
    const last_page = qparams.get('last_page') ?? 1;

    useDocumentTitle('Detail Tagihan Bulanan')
    const contentHeader = {
        title: 'Detail Tagihan Bulanan',
        breadcrumbs: [
            {
                name: 'Home',
                link: '/'
            },
            {
                name: 'Info Tagihan',
                link: `/info-tagihan?history=${history}&last_page=${last_page}`
            },
        ]
    }

    // get data detail
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [pelanggan, setPelanggan] = useState({
        kode_pelanggan: null,
        nama_pelanggan: null,
        alamat: null,
        golongan: null
    });
    const [tagihan, setTagihan] = useState({
        year: 0,
        month: 0,
        m3_akhir: 0,
        m3_awal: 0,
        m3_pemakaian: 0,
        status: null,
        foto_meter: null,
        tanggal_pembayaran: null,
        kanal_pembayaran: null,
        kasir: null,
        foto_meter: null,
        total_tagihan: 0,
        biaya: {
            pemakaian_air: 0,
            admin: 0,
            denda: 0
        }
    });

    useEffect(() => {
        async function __init(id) {
            try {
                const response = await getDetailTagihan(id);
                if (
                    response?.data?.data?.pelanggan === undefined
                    || response?.data?.data?.tagihan === undefined
                ) {
                    navigate("/info-tagihan");
                } else {
                    const data_pelanggan = response.data.data.pelanggan;
                    const data_tagihan = response.data.data.tagihan;

                    // update state
                    setPelanggan({
                        kode_pelanggan: data_pelanggan?.kode_pelanggan ?? null,
                        nama_pelanggan: data_pelanggan?.nama ?? null,
                        alamat: data_pelanggan?.alamat ?? null,
                        golongan: data_pelanggan?.golongan ?? null
                    });

                    let _dataTagihan = {
                        year: data_tagihan?.periode_tahun ?? 0,
                        month: data_tagihan?.periode_bulan ?? 0,
                        m3_akhir: data_tagihan?.m3_akhir ?? 0,
                        m3_awal: data_tagihan?.m3_awal ?? 0,
                        m3_pemakaian: data_tagihan?.m3_pemakaian ?? 0,
                        status: data_tagihan?.status ?? null,
                        foto_meter: data_tagihan?.path_foto_meter ?? null,
                        tanggal_pembayaran: data_tagihan?.tanggal_pembayaran ?? null,
                        kanal_pembayaran: data_tagihan?.kanal_pembayaran ?? null,
                        kasir: data_tagihan?.kasir ?? null,
                        total_tagihan: data_tagihan?.total_tagihan ?? 0,
                        biaya: {
                            pemakaian_air: 0,
                            admin: 0,
                            denda: 0
                        }
                    }
                    if (_dataTagihan.month > 0) _dataTagihan['month'] = global.convert_month(_dataTagihan.month);

                    // get detail tagihan
                    for (let i = 0; i < data_tagihan.detail.length; i++) {
                        if (data_tagihan.detail[i].item.toLowerCase().includes("pemakaian air")) {
                            _dataTagihan.biaya.pemakaian_air = data_tagihan.detail[i].biaya;
                        } else if (data_tagihan.detail[i]?.item?.toLowerCase().includes("biaya admin")) {
                            _dataTagihan.biaya.admin = data_tagihan.detail[i].biaya;
                        } else if (data_tagihan.detail[i]?.item?.toLowerCase().includes("denda")) {
                            _dataTagihan.biaya.denda = data_tagihan.detail[i].biaya;
                        }
                    }

                    // updating state
                    setTagihan(_dataTagihan);
                    setIsLoading(false);
                }
            } catch (error) {
                navigate("/info-tagihan");
            }
        }
        __init(id);
    }, []);

    return (
        <Content contentHeader={contentHeader} >
            {isLoading && <div className="w-full flex flex-col gap-5">
                <div className="skeleton h-4 w-full"></div>
                <div className="skeleton h-4 w-10/12"></div>
                <div className="skeleton h-4 w-9/12"></div>
            </div>}
            {!isLoading && <div className="flex gap-3 flex-col md:flex-row">
                <div className="w-full md:w-9/12 flex flex-col gap-5">
                    {/* Data Pelanggann */}
                    <div className="w-full prose max-w-none">
                        <Link
                            to={`/info-tagihan?history=${history}&last_page=${last_page}`}
                            className="btn btn-sm btn-info text-white"
                            style={{
                                textDecoration: 'none'
                            }}
                        >Kembali</Link>
                        <h3>Informasi Pelanggan</h3>

                        <div className="w-full flex flex-col md:flex-row border-t border-base-400 py-2">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Kode Pelanggan</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">{pelanggan.kode_pelanggan ?? '-'}</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Nama Pelanggan</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">{pelanggan.nama_pelanggan ?? '-'}</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Alamat</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">{pelanggan.alamat ?? '-'}</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Golongan</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">{pelanggan.golongan ?? '-'}</span>
                            </div>
                        </div>
                    </div>

                    {/* Periode Tagihan */}
                    <div className="w-full prose max-w-none">
                        <div className="flex items-center gap-5 mb-4">
                            <h3 className='m-0'>Periode Tagihan</h3>
                            <button className="btn btn-base-100 btn-sm">
                                {tagihan.month ?? '-'} - {tagihan.year ?? '-'}
                            </button>
                        </div>

                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Stan Bulan Lalu</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content font-medium"><b>{numeral(tagihan.m3_awal).format('0,0') ?? 0}</b></span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Stan Bulan Sekarang</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content font-medium"><b>{numeral(tagihan.m3_akhir).format('0,0') ?? 0}</b></span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Pakai (m)<sup>2</sup></span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content"><b>{numeral(tagihan.m3_pemakaian).format('0,0') ?? 0} <sup>2</sup></b></span>
                            </div>
                        </div>
                    </div>

                    {/* Perhitungan Rekening */}
                    <div className="w-full prose max-w-none">
                        <h3>Perhitungan Rekening</h3>

                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Biaya Pemakaiann</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">Rp. {numeral(tagihan.biaya.pemakaian_air).format('0,0') ?? 0}</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Biaya Admin</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">Rp. {numeral(tagihan.biaya.admin).format('0,0') ?? 0}</span>
                            </div>
                        </div>
                        {/* <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Dana Meter</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">Rp. 5000</span>
                            </div>
                        </div> */}
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Denda</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">Rp. {numeral(tagihan.biaya.denda).format('0,0') ?? 0}</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Status Pembayaran</span>
                            </div>
                            <div className="flex-grow">
                                {tagihan.status == 'LUNAS' && <div className="badge badge-success text-white">
                                    {tagihan.status}
                                </div>}
                                {tagihan.status == 'BELUM LUNAS' && <div className="badge bg-red-500 text-white">
                                    {tagihan.status}
                                </div>}
                                {(tagihan.status != 'BELUM LUNAS' && tagihan.status != 'LUNAS') && <div className="badge badge-warning text-white">
                                    {tagihan.status}
                                </div>}
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Tanggal Pembayaran</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">{tagihan.tanggal_pembayaran ?? '-'}</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Kanal Pembayaran</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">{tagihan.kanal_pembayaran ?? '-'}</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Kasir</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content">{tagihan.kasir ?? '-'}</span>
                            </div>
                        </div>
                        <div className="w-full flex border-t border-base-400 py-2 flex-col md:flex-row">
                            <div className="md:w-4/12">
                                <span className="font-medium text-base-content">Total Tagihan</span>
                            </div>
                            <div className="flex-grow">
                                <span className="font-normal text-base-content"><b>Rp. {numeral(tagihan.total_tagihan).format('0,0') ?? 0}</b></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-grow">
                    <div className="w-full prose max-w-none">
                        <h3>Foto Meter</h3>
                        <div className="w-full">
                            {tagihan.foto_meter && <img
                                src={tagihan.foto_meter}
                                alt="Foto Meter"
                                className="w-full h-full object-fit cursor-zoom-in m-0"
                                style={{
                                    borderRadius: 10
                                }}
                                onError={(e) => {
                                    e.target.error = null;
                                    e.target.src = `${process.env.PUBLIC_URL}/noimage.png`;
                                }}
                            />}
                        </div>
                    </div>
                </div>
            </div>}
        </Content>
    )
}

export default Detail